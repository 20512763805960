<template>
  <div id="dialogConfirmScore">
        <div class="border-top border-1 border-dark mb-2"></div>
        <div class=" mb-4">
            <div>
                <p>Tổng điểm KPI: {{ dataPersonal?.total_percent_kra }}%</p>
                <p>Điểm tích họp tuần: {{ dataPersonal?.total_score_meeting }}đ</p>
                <p>Bạn có chắc chắn muốn lưu điểm của nhân sự không?</p>
            </div>
            <span slot="footer" class="dialog-footer d-flex justify-content-end">
                <div>
                <button
                    @click="close"
                    style="background-color: rgba(202, 202, 202, 1)"
                    class="rounded p-1 pl-3 pr-3 text-dark mr-3 shadow-none border-0"
                >
                    Huỷ bỏ
                </button>
                </div>
                <div>
                <button @click="confirm" :disabled='loadingConfirm' style="background-color: rgba(93, 4, 176, 1)" class="rounded p-1 pl-2 pr-2 mr-3 text-white shadow-none border-0">
                  <i v-bind:class="[loadingConfirm ? 'el-icon-loading' : '']" class="mr-1 text-white"></i>Xác nhận
                </button>
                </div>
            </span>
        </div>
  </div>
</template>

<script>
import { UPDATE_DATA_KPI_TEAM_LEAD } from '../../../../../core/services/store/kpi/kpi.module';
export default {
  components: {},
  props: {
    dataPersonal: {
      type: Number,
      default: 0,  
    },
    user_id: {
      type: Number,
      default: 0,  
    },
    month: {
      type: String,
      default: '',  
    },

    department: {
      type: Number,
      default: 0,  
    },
  },
  data() {
    return {
      loadingConfirm: false
    };
  },

  methods: {
    confirm() {
      this.loadingConfirm= true;
      this.$store.dispatch(UPDATE_DATA_KPI_TEAM_LEAD, {
        department_id: this.department,
        user_id:this.user_id,
        month:this.month,
        details:this.dataPersonal.details,
        week_meetings:this.dataPersonal.week_meetings,
        description: this.dataPersonal.description,
      }
      ).then((res) => {
        this.close();
        if (!res.error) {
          this.$notify.success({
          title: 'Thành công',
          message: 'Chấm thành phẩm thành công!',
          type: 'success'
        });
        }else {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Chấm thành phẩm thất bại!',
            type: 'error'
          });
        }
      })
    },
    close() {
      this.$emit('closeDialog');
      this.loadingConfirm= false;
    }
  },
};
</script>

<style>

</style>
