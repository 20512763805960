  <template>
    <div class="row" id="JobKpiTeamlead">
      <div class="w-100">
         <div id="panel-1" class="panel mt-1 m-2">
            <div class="m-2">
                <div class="row m-2">
                    <div class="col-xl-2 col-md-2 col-sm-6">
                        <label for=""><b>Tên/Mã nhân sự</b></label>
                        <div class="form-group w-100">
                          <el-input class="shadow-sm" type="text" v-model="query.keyword" clearable
                            placeholder="Nhập tên, sđt, email"></el-input>
                        </div>
                    </div>
                    <div class="col-xl-2 col-md-2 col-sm-6">
                            <label for=""><b>Thời gian</b></label><br>
                            <el-date-picker
                             :clearable="false"
                            class="btn-outline-secondary rounded w-100"
                            v-model="query.month"
                            size="small"
                            type="month"
                            value-format="yyyy-MM"
                            placeholder="Chọn tháng"
                          >
                        </el-date-picker>
                    </div>
                    <div class="col-xl-2 col-md-2 col-sm-6">
                        <label for=""><b>Đơn vị</b></label>
                        <el-select class="w-100" v-model="query.department_id" placeholder="Chọn đơn vị" clearable filterable>
                            <el-option
                              v-for="item in allDepartment"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select>
                    </div>
                    <div class="col-xl-2 col-md-2 col-sm-6">
                        <label for=""><b>Trạng thái</b></label><br>
                        <el-select class="w-100" v-model="query.status" placeholder="Chọn trạng thái" clearable>
                            <el-option
                              v-for="item in statusScoreKpi"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id">
                              <span style="float: left">{{ item.name }}</span>
                            </el-option>
                          </el-select>
                    </div>
                    <div class="col-xl-2 col-md-2 col-sm-6">
                      <button @click="srearch" :disabled='loading' style="background-color: #472f92;" class="btn btn-primary mr-2 mt-4">
                        <i v-bind:class="[loading ? 'el-icon-loading' : 'el-icon-search']" class="mr-1 text-white"></i>Tìm kiếm
                    </button>
                  </div>
                </div>
                <div>
                    <div class="card-body">
                        <div class="example mb-10">
                          <div class="row filter_form">
                          </div>
                          <div class="example-preview table-responsive overflow-auto mt-3">
                            <table class="table text-left table-hover table-bordered table-vertical-center b-table" v-loading="loading" element-loading-text="Loading...">
                              <thead>
                                <tr style="background-color: #F8F8F8;">
                                  <th>Tên nhân sự</th>
                                  <th>Mã nhân sự</th>
                                  <th >Đơn vị</th>
                                  <th>% KPI tổng</th>
                                  <th>Điểm họp tuần</th>
                                  <th>Người chấm</th>
                                  <th >Trạng thái</th>
                                  <th  style="width:6%" class="text-center">Hành động</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, index) in dataKpiPersonal" :key="item.id">
                                    <td>{{ item?.user?.name ?? '' }}</td>
                                    <td>{{ item.user?.id + 100000??'' }}</td>
                                    <td>{{ item?.department?.name ?? '' }}</td>
                                    <td>{{ item.total_point_kra ? item.total_point_kra + '%' : 'Chưa có' }}</td>
                                    <td>{{ item.total_point_meeting ? item.total_point_meeting : 'Chưa có' }}</td>
                                    <td>{{ item.assessor ? item?.assessor?.name : '' }}</td>
                                    <td >
                                      <div> <spans tyle="text-align:center" class="text-white rounded p-2 text-center" :style="statusKpiCss[item.status]">{{ statusKpi[item.status] }}</spans></div>
                                    </td>
                                    <td>
                                      <div class="d-flex justify-content-center">
                                          <button title="Xem" v-if="item.status == 2" @click="dialogKpiPersional(true, item)" type="button" class="btn btn-outline-primary p-0"> <i class="el-icon-view p-2"></i></button>
                                          <button title="Chấm" v-else @click="dialogKpiPersional(false, item)"  type="button" class="btn btn-outline-warning p-0"> <i class="el-icon-edit p-2"></i></button>
                                      </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{
                            totalData ? totalData : 0 }}</b></div>
                        </div>
                    </div>
                        <div class="edu-paginate mx-auto d-flex justify-content-center">
                            <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
                            :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
                            :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
                            :next-class="'page-link'" :page-class="'page-item'">
                            </paginate>
                    </div>
                </div>
                <el-dialog
                    top="2%"
                    width="99%"
                    :title="'Chấm KPI nhân sự:'+ ' ' +user_name_detail"
                    :visible.sync="show_score_kpi"
                >
                <scoreKpi
                   :dataPersonal="dataPersonal"
                   :payload="payload"
                   :is_disable="action"
                   @openDialog="handleDialogConfirm"
                   @closeDialog="show_score_kpi=false"
                   >
                </scoreKpi>
              </el-dialog> 

              <el-dialog
              class="dialog-comfirm-score"
              style="border-radius: 15%"
              width="25%"
              top="30vh"
              title="Chấm điểm nhân sự"
              :visible.sync="show_dialog_confirm"
            >
            <dialogConfirmScore
              :dataPersonal="dataPersonal"
              :user_id="user_update"
              :month="query.month"
              :department="department_id"
              @closeDialog="handleAction"
            ></dialogConfirmScore> 
          </el-dialog>
            </div>
         </div>
      </div>
    </div>
  </template>
  
  <script>
  import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
  import {GET_DEPARTMENT_BY_DEPARTMENT_HEAD} from "@/core/services/store/profile.module";
  import scoreKpi from "../TeamLead/component/ScorePersionalComponent.vue";
  import dialogConfirmScore from "./component/dialogConfirmScore.vue";
  import { GET_DATA_KPI_PERSIONAL, GET_DATA_DETAIL_KPI } from "../../../../core/services/store/kpi/kpi.module";
  import {mapGetters} from "vuex";
  export default {
      components: {
        scoreKpi, dialogConfirmScore
      },
      data() {
          return {
              page: 1,
              last_page: 1,
              month: '',
              totalData: '',
              loading: '',
              show_dialog_confirm: false,
              sum_result_kip: 100,
              sum_score_week: 5,
              allDepartment:[],
              payload: '', 
              dataPersonal:'',
              dataKpiPersonal:[],
              loadingSearch: false,
              is_tbp: false,
              show_score_kpi: false,
              action: false,
              user_update: '',
              user_name_detail: '',
              department_id: '',
              query: {
                keyword: '',
                month: '',
                unit: '',
                status: '',
              },
              statusKpiCss: {
                  0: "background-color: rgba(255, 0, 0, 1); text-white",
                  1: "background-color: rgba(18, 0, 219, 1); text-white",
                  2: "background-color: rgba(0, 128, 13, 1); text-white",
                },
              statusKpi: {
                  0: "Chưa nhập thành phẩm",
                  1: "Cần chấm",
                  2: "Đã chấm",
              },
              statusScoreKpi: [
                  {id: 0,  name: 'Chưa nhập thành phẩm'},
                  {id: 1,  name: 'Cần chấm'},
                  {id: 2,  name: 'Đã chấm'},
              ],
          }
      },
      computed: {
          ...mapGetters(['currentUser'])
      },
      mounted() {        
        this.getMonthCurent();
        this.getDepartmentByUserId(this.currentUser.id);
        this.getDataKpiPersional();
          this.$store.dispatch(SET_BREADCRUMB, [
              {title: "Chấm điểm KPI nhân sự", icon: 'fas fa-list'},
          ]);
      },
  
      methods: {
        dialogKpiPersional(action, item) {
          this.checkError()
          this.user_update = item?.user?.id;
          this.user_name_detail = item?.user?.name;
          this.department_id = item?.department_id;
          if(!item?.user) {
            this.$message({
              type: 'error',
              message: 'Không tìm thấy user',
              showClose: true
            })
            return;
          }
          let payload = {
            user_id : this.user_update,
            month: this.query.month,
            department_id: this.department_id,
          }
          this.payload = payload;

          this.$store.dispatch(GET_DATA_DETAIL_KPI, payload
          ).then((res) => {
            this.handleLink(res.data.details)
            this.dataPersonal = res.data
            this.show_score_kpi = true
            this.action = action;
          }).catch(() => {

          })
        },

        handleLink(res) {  
          Object.values(res).forEach(function (item) {  
            if (item.results) {  
              const urlPattern = /(https?:\/\/[^\s]+)/g;  
              item.results = item.results.replace(urlPattern, (url) => {  
                return `<a href="${url}" class="styled-link">${url}</a>`;  
              });  
            }  
          });  
          return res;
        },

        srearch() {
          this.page = 1;
          this.getDataKpiPersional();
        },

        clickCallback(obj) {
            this.page = obj
            this.getDataKpiPersional();
        },
        
        getDataKpiPersional() {
          this.loading = true;
          this.$store.dispatch(GET_DATA_KPI_PERSIONAL, {
            page:this.page,
            keyword: this.query.keyword,
            month: this.query.month,
            department_id: this.query.department_id,
            status: this.query.status
            }).then((res) => {
              this.dataKpiPersonal = res.data.data;
              this.totalData = res.data.total;
              this.last_page = res.data.last_page
              this.loading = false;
            }).catch(() => {
              this.loading = false;
            })
        },
        checkError(){
          if(!this.query.month){
            this.$notify.warning({
                title: 'Thiếu dữ liệu',
                message: `Vui lòng nhập tháng để tiếp tục`
            });
          return;
          }
        },

        getDepartmentByUserId(user_id){
          this.$store.dispatch(GET_DEPARTMENT_BY_DEPARTMENT_HEAD, { user_id
            }).then((res) => {    
              this.allDepartment =  res.data;
            }).catch(() => {
               
            })
        }, 

        handleDialogConfirm(sum_score_week,sum_result_kip) {{
          if(sum_score_week) {
            this.sum_score_week = sum_score_week;
          }
          if(sum_result_kip) {
            this.sum_result_kip = sum_result_kip;
          }
            this.show_dialog_confirm=true
        }},
        
        getMonthCurent() {
          const date = new Date();
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          this.query.month = `${year}-${month}`;
        },

        handleAction() {
          this.show_dialog_confirm=false;
          this.show_score_kpi=false;
          this.getDataKpiPersional()
            
        }
      }
  }
  </script>
  
  <style>
  #JobKpiTeamlead .dialog-comfirm-score .el-dialog__body {
    padding: 1px 20px;
  }
  </style>
  
  
  