<template>
  <div id="tableScoreWeekMeeting" class="mt-4" v-if="dataScoreWeekMeeting.week_meetings.length > 0">
      <div>
        <h3>II. Kết quả họp tuần</h3>
      </div>
      <div class="mb-3 mt-3">
          <b style="background-color: rgb(93, 4, 176); border-radius: 8px; padding: 12px" class="mb-2 text-white">Điểm tích họp tuần: <span>{{ dataScoreWeekMeeting.total_score_meeting >= 0 && dataScoreWeekMeeting.total_score_meeting !== null ? dataScoreWeekMeeting.total_score_meeting : "Chưa có"}}</span></b>
      </div>
      <div class="example-preview w-75">
          <table class="table table-vertical-center table-bordered" element-loading-text="Loading...">
            <thead>
              <tr style="background-color: #F8F8F8; word-break: auto-phrase">
                <th>Tiêu chí</th>
                <th v-for="(item, index) in dataScoreWeekMeeting.week_meetings[1].week">Kết quả tuần {{  index + 1 }}</th>
              </tr>
            </thead>
            <tbody v-if="dataScoreWeekMeeting.week_meetings">
            <tr v-for="(scoreWeekMeeting, index) in dataScoreWeekMeeting.week_meetings" :key="index">
              <td style="word-break: break-word; width:25%">{{ scoreWeekMeeting.criteria_name }}</td>
              <td v-for="(value_week, i) in scoreWeekMeeting.week" :key="i">
                <el-select
                    :disabled="is_disable"
                    v-model="value_week[`week-${i+1}`]"
                    class="input_percents border-0"
                    placeholder="Chọn"
                    :class="changeColor(value_week[`week-${i+1}`])"
                    @change="changeWeekScore(dataScoreWeekMeeting.week_meetings)"
                >
                  <el-option
                      v-for="item in percents"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                  </el-option>
                </el-select>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="text-center">
              <td colspan="7">
                Không có dữ liệu
              </td>
            </tr>
            </tbody>
          </table>
      </div>
      <div class="w-100">
          <label for=""><b>Nhận xét:</b></label>
          <el-input :disabled="is_disable" :style="is_disable ? 'color:rgb(0 0 0 / 42%)' : ''" class="w-100" v-model="dataScoreWeekMeeting.description"  placeholder="Nhập nhận xét"></el-input>
      </div>
    </div>
</template>

<script>
export default {
  components: {},
  props: {
    dataScoreWeekMeeting: {
      type: [Array, Object], // Chấp nhận cả mảng và đối tượng
      default: () => []
    },

    is_disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentYear: '',
      currentMonth: '',
      weekCounts: '',
      dataMetting: [],
      percents: [
        {id: 0, value: "Không đạt"},
        {id: 1, value: "Đạt"},
      ],
    };
  },
  computed: {},
  mounted() {
    this.getCurrentYearAndMonth();
  },

  methods: {
    changeColor(status) {
      switch (status) {
        case 0:
          return 'kra-text-red';
        case 1:
          return 'kra-text-green';
        default:
          return 'kra-text-black';
      }
    },

    getCurrentYearAndMonth() {
      const date = new Date();
      this.currentYear = date.getFullYear();
      this.currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    },

     changeWeekScore(data) {
      var count_total = '';
      data.forEach((item) => {
        item.week.forEach((v, i) => {
          let count_item = v[`week-${i+1}`];
          if(count_item) {
            count_total++
          }
          
          if(!count_total && count_item == 0) {
            count_total = 0
          }
        });
      });
        return this.dataScoreWeekMeeting.total_score_meeting = count_total
      },
  },
}
</script>

<style>
#tableScoreWeekMeeting .input_percents .el-input__inner{
  border: none !important;
  font-size: 16px;
  font-weight: 400;
}
</style>