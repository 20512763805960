<template>
    <div>
          <div>
            <h3>I. Kết quả KPI</h3>
          </div>
          <div class="mb-1">
            <el-button style="background-color: rgb(93, 4, 176); border-radius: 8px; padding: 12px" class="sum-result-v1 mb-2 text-white"
              >% KPI tổng: {{ dataPersonal.total_percent_kra >= 0 && dataPersonal.total_percent_kra !==null ? dataPersonal.total_percent_kra + '%' : 'Chưa có' }}</el-button>
          </div>
          <div style="overflow: scroll; max-height: 500px; min-height: 134px">
            <div class="example-preview table-responsive">
              <div class="table-container">
                <table class="table table-vertical-center table-bordered" element-loading-text="Loading...">
                  <thead>
                    <tr style="background-color: #f8f8f8; word-break: auto-phrase">
                      <th style="width:18%">KRAs</th>
                      <th style="width:12%">Yêu cầu thành phẩm</th>
                      <th style="width:20%">Thành phẩm</th>
                      <th style="width:5%">Trọng số</th>
                      <th style="width:7%" v-for="(item, index) in dataPersonal.details[0].kra_manager_weeks">Kết quả tuần {{ index + 1 }}</th>
                      <th style="width:7%">Kết quả KRA</th>
                      <th style="width:10%">Nhận xét</th>
                    </tr>
                  </thead>
                  <tbody v-if="dataPersonal">
                    <tr v-for="(item, index) in dataPersonal.details" :key="index">
                      <td class="text-break">{{ item?.name ?? '' }}</td>
                      <td class="line_hieght_table text-break">{{ item?.request_result ?? '' }}</td>
                      <td class="line_hieght_table text-break"><span v-html="item?.results ?? ''"></span></td>
                      <td class="line_hieght_table" style="font-size: 15px">
                        {{ item?.percent_score + "%" }}
                      </td>
                      <td v-for="(value_week, i) in item?.kra_manager_weeks">
                        <el-select
                          :title="!item.results ? 'Không thể chấm do chưa có thành phẩm' : ''"
                          @change="changeAllResultKpi(item)"
                          :class="changeColor(value_week?.percent_results)"
                          :disabled="is_disable || !item.results"
                          class="input_percent border-0"
                          v-model="value_week.percent_results"
                          placeholder="Chọn"
                        >
                          <el-option
                            v-for="item in percents"
                            :key="item.count"
                            :label="item.value + '%'"
                            :value="item.count"
                          >
                          </el-option>
                        </el-select>
                      </td>
                      <td class="pt-3" style="font-size: 18px">
                        {{ item.percent_results !== null ? item.percent_results + "%" : 'Chưa có' }}
                      </td>
                      <td>
                        <el-input id="input-comment" placeholder="Nhập nhận xét..." style="border: 0;" maxlength="200" :disabled="is_disable || !item.results" v-model="item.description" clearable>
                        </el-input>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr class="text-center">
                      <td colspan="10">Không có dữ liệu</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
    </div>
        
</template>

<script>
export default {
  components: {},
  props: {
    dataPersonal: {
      type: Object,
      default: [],
    },

    is_disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1, 
      percents: [
        { count: 0, value: 0 },
        { count: 30, value: 30 },
        { count: 60, value: 60 },
        { count: 90, value: 90 },
        { count: 100, value: 100 },
      ],
    };
  },

  methods: {
    changeColor(count) {
      switch (count) {
        case 0:
          return 'kra-text-red';
        case 30:
        case 60:
        case 90:
          return 'kra-text-blue';
        case 100:
          return 'kra-text-green';
        default:
          return 'kra-text-black';
      }
    },

    checkCountWeek(item) {
        let count = ''
        Object.values(item.kra_manager_weeks).forEach(function (item) {
            if(item.percent_results || item.percent_results == 0) {
              count++
            }
        });
        return count;
      }, 

    changeAllResultKpi(items) {  
      let totalAverage = 0;
      let count = this.checkCountWeek(items);
      items.kra_manager_weeks.forEach((item) => {
        if (count <= 0) {
          item.kras_result = 0;
          return;
        }
        
        const total = items.kra_manager_weeks.reduce((sum, value) => {
          return sum + value.percent_results;
        }, 0);

        
        let average = total / count;
        average = average.toFixed(2);
        
        items.percent_results = average;
        totalAverage += average;
      });
      this.all_result_kip = Math.round(totalAverage / count);
      this.result_total();
    },
    result_total() {
      let result = 0;
      this.dataPersonal.details.forEach((item) => {
       let row_resule = (item.percent_results * item.percent_score) / 100;
       result += row_resule
      });
      this.dataPersonal.total_percent_kra = result.toFixed(2);
    }
  },
 
};
</script>

<style>
input#input-comment {
    border: 0;
}
.kra-text-black input{
  color: black !important;
}
.kra-text-blue input{
  color: #0000ffc4 !important;
}
.kra-text-red input{
  color:  #FF0000 !important;
}
.kra-text-green input{
  color: #006B04!important;
  
}

thead th {
  position: sticky;
  top: 0;
  background-color: #f8f8f8;
  z-index: 10; /* Đảm bảo header không bị che bởi nội dung cuộn */
  text-align: center;
}

.table-container {
  max-height: 400px; /* Bạn có thể điều chỉnh chiều cao theo nhu cầu */
  overflow-y: auto;
}

.line_hieght_table {
  line-height: 1.5;
}

.styled-link {
  color: rgba(0, 102, 255, 1);
}

.styled-link:hover {
  color: rgba(0, 102, 255, 1);
  text-decoration: underline !important;
  cursor: pointer;
}
</style>
