<template>
  <div>
      <div id="ScorePersonalComponent">
        <div class="version-1 m-2">
          <tableResultWeekKpi
          :dataPersonal="dataPersonal"
          :is_disable="is_disable"
          @kipTableResult="kipTableResult">
          </tableResultWeekKpi>
        </div>
        <div class="version-2 m-2">
          <div>
            <tableScoreWeekMeeting
              :dataScoreWeekMeeting="dataPersonal"
              :is_disable="is_disable"
              @all_score_week="handleData"
            ></tableScoreWeekMeeting>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer d-flex justify-content-end">
        <div>
          <button
              @click="closeDialog"
              style="background-color: rgba(202, 202, 202, 1)"
              class="rounded p-1 pl-2 pr-2 text-dark mr-3 shadow-none border-0"
          >
            Huỷ bỏ
          </button>
        </div>
        <div>
          <button
            v-if="!is_disable"
            @click="openDialogConfirm"
            style="background-color: rgba(93, 4, 176, 1)"
            class="rounded p-1 pl-2 pr-2 mr-3 text-white shadow-none border-0"
          >
            Xác nhận
          </button>
        </div>
        <div>
          <button
              v-if="is_disable"
              @click="is_disable = false"
              style="background-color: rgba(93, 4, 176, 1); width: 60px"
              class="rounded p-1 pl-2 pr-2 mr-3 text-white shadow-none border-0"
          >
            Sửa
          </button>
        </div>
      </span>
  </div>
</template>

<script>
import tableScoreWeekMeeting from "../component/tableScoreWeekMeeting.vue";
import tableResultWeekKpi from "../component/tableResultWeekKpi.vue";
export default {
  components: {
    tableScoreWeekMeeting, tableResultWeekKpi
  },
  props: {
    dataPersonal: {
      type: Object,
      default: [],
    },
    is_disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { 
      currentYear: "",
      currentMonth: "",
      weekCounts: "",
      value_week: "",
      sum_result_kip: '',
      sum_score_week: '',
    };
  },

  computed: {},
  mounted() {
    this.getCurrentYearAndMonth();
  },

  methods: {
    getCurrentYearAndMonth() {
        const date = new Date();
        this.currentYear = date.getFullYear();
        this.currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    },
  
    openDialogConfirm() {
      this.$emit('openDialog', this.sum_score_week, this.sum_result_kip)
    },
    kipTableResult(obj) {
        this.closeDialog(obj);
    },
    handleData(kip, score_week) {
      if(kip) {
          this.sum_result_kip = kip
      }
      if(score_week) {
          this.sum_score_week = score_week
      }
    },
    closeDialog(obj) {
      this.$emit('closeDialog', obj)
    }
  },
};
</script>

<style>
 #ScorePersonalComponent .input_percent .el-input__inner{
    border: none !important;
    font-size: 16px;
    font-weight: 400;
  }

  #ScorePersonalComponent .input_percent .line_height_table {
    line-height: 30px;
  }

</style>
